<template>
  <div class="live-container">
    <div class="header"></div>
    <div class="body">
      <img class="bg" src="../../assets/live/wang-bg.png" alt=""/>
      <div class="inner-container">
        <div class="date-container">
          <div class="date-list">
            <div
                :class="['date-item', { active: index === currentActive && 'active'}]"
                v-for="(date, index) in dateList"
                :key="date.id"
                @click="changeDate(index, date.id)"
            >
              <p class="date">{{ date.date.substr(5, 9) }}</p>
              <p class="week-of-day">{{ date.date | weekDay }}</p>
            </div>
          </div>
        </div>
        <div class="live-list">
          <div class="live-item" v-for="item in liveList" :key="item.id">
            <div class="left-time">{{ item.beginTime | formatDate }} - {{ item.endTime | formatDate }}</div>
            <div class="right-info">
              <div class="forum-title">{{ item.subMeetingName }}</div>
              <div class="forum-title-en">{{ item.subMeetingNameEn }}</div>
              <div class="forum-address">
                <i class="el-icon-location-outline icon"></i>
                {{ item.placesName }}
              </div>
              <div class="btn-container">
                <div class="btn-item" @click="liveClick(item.liveAddress)">
                  <img src="../../assets/live/icon-play.png" alt="">
                  <span>直播频道</span>
                </div>
                <div class="btn-item" v-if="item.liveAddressEn" @click="liveClick(item.liveAddressEn)">
                  <img src="../../assets/live/icon-play.png" alt="">
                  <span>LIVE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        技术支持：浙江云上叮咚科技有限公司
      </div>
    </div>

  </div>
</template>

<script>
import {getLiveStremList, getLiveDateList} from "@/api/live";

export default {
  name: "liveStrem",
  data() {
    return {
      dateList: [],
      liveList: [],
      currentActive: 0
    };
  },
  filters: {
    weekDay(value) {
      if (value) {
        const dateArr = value.split("-");
        const date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
        const weekDay = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
        return weekDay[date.getDay()];
      }
    },
    formatDate(value) {
      if (value) {
        const dateArr = value.split(' ')[1]
        return dateArr.substr(0, 5);
      }
    }
  },
  created() {
    this.getDateList();
  },
  methods: {
    async loadData(dateId) {
      const res = await getLiveStremList({
        dateId: dateId,
        isLive:1,
        pageNum: 1,
        pageSize: 100
      });
      if (res.code === 200) {
        this.liveList = res.data.list;

      }
    },
    // 获取日期列表
    async getDateList() {
      let result = await getLiveDateList({
        meetingId: localStorage.getItem('meetId')
      });
      if (result.code === 200) {
        this.dateList = result.data
        this.loadData(this.dateList[this.currentActive].id)
        // this.dateList = [{ date: '2022-05-12'}, { date: '2022-05-12'}];
      }
    },
    changeDate(index, dateId) {
      if (this.currentActive === index) {
        return;
      }
      this.currentActive = index;
      this.loadData(dateId)
    },
    liveClick(address) {
      window.location.href = address
    }
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>